<template>
  <div>
    <div class="contents">
      <div id="lineChart"></div>
      <div id="barChart"></div>
    </div>
    <!-- <el-empty description="暫時還沒有數據哦"></el-empty> -->
  </div>
</template>

<script>
import { getOrderInfo } from "@/api/homePage";
export default {
  data() {
    return {
      orderInfo: {},
    };
  },
  created() {
    this.getOrderInfoObject();
  },
  mounted() {
    // this.initLineChart();
    // this.initBarChart();
  },
  methods: {
    // 折线图
    initLineChart() {
      // let dateArray = this.orderInfo.day;
      let lineChart = document.getElementById("lineChart");
      // 基于准备好的dom，初始化echarts实例
      const myChart = this.$echarts.init(lineChart);
      const option = {
        title: {
          text: "總銷售額/利潤趋势图",
          left: "center",
          textStyle: {
            fontSize: 14,
          },
        },
        tooltip: {
          textStyle: {
            color: "balck", // 设置文字颜色为黑色
          },
          borderWidth: 1, // 设置边框宽度为1像素
          borderColor: "#999999", // 设置边框颜色为灰色
          backgroundColor: "#fff", // 设置背景颜色为白色
          trigger: "axis",
          axisPointer: {
            type: "cross", // 设置 axisPointer 类型为十字准星型
            lineStyle: {
              type: "dashed", // 设置虚线样式
            },
          },
        },
        legend: {
          data: ["總銷售額", "利潤"],
          top: "20px",
          right: "20px",
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.orderInfo.day,
          axisLine: {
            lineStyle: {
              width: 2,
              // color: "rgb(168,168,168)",
              color: "black",
            },
          },
        },
        yAxis: {
          name: "單位/元",
          type: "value",
          boundaryGap: [0, "50%"],
          axisLine: {
            show: true,
            lineStyle: {
              width: 2,
              // color: "rgb(168,168,168)",
              color: "black",
            },
          },
          splitLine: {
            lineStyle: {
              color: "rgb(168,168,168)",
            },
          },
          axisTick: {
            show: false, // 隐藏 Y 轴刻度线
          },
        },
        grid: {
          left: "10%",
        },
        series: [
          {
            data: this.orderInfo.total_pay_price,
            name: "總銷售額",
            type: "line",
            smooth: true,
            itemStyle: {
              color: "#988de8",
            },
            // areaStyle: {
            //   color: {
            //     type: "linear",
            //     x: 0,
            //     y: 0,
            //     x2: 0,
            //     y2: 1,
            //     colorStops: [
            //       {
            //         offset: 0,
            //         color: "rgba(255,0,0,.5)",
            //       },
            //       {
            //         offset: 0.7,
            //         color: "rgba(255,0,0,0)",
            //       },
            //     ],
            //   },
            // },
          },
          {
            data: this.orderInfo.profit,
            name: "利潤",
            type: "line",
            smooth: true,
            itemStyle: {
              color: "#91b0ff",
            },
            // areaStyle: {
            //   color: {
            //     type: "linear",
            //     x: 0,
            //     y: 0,
            //     x2: 0,
            //     y2: 1,
            //     colorStops: [
            //       {
            //         offset: 0,
            //         color: "rgba(159, 193, 255,.5)",
            //       },
            //       {
            //         offset: 0.7,
            //         color: "rgba(159, 193, 255,0)",
            //       },
            //     ],
            //   },
            // },
          },
        ],
      };
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    initBarChart() {
      let barChart = document.getElementById("barChart");
      const myChart = this.$echarts.init(barChart);
      const option = {
        title: {
          text: "訂單量",
          left: "center",
          textStyle: {
            fontSize: 14,
          },
        },
        tooltip: {
          textStyle: {
            color: "balck", // 设置文字颜色为黑色
          },
          borderWidth: 1, // 设置边框宽度为1像素
          borderColor: "#999999", // 设置边框颜色为灰色
          backgroundColor: "#fff", // 设置背景颜色为白色
          trigger: "axis",
        },
        xAxis: {
          type: "category",
          data: this.orderInfo.day,
          axisLine: {
            lineStyle: {
              width: 2,
              // color: "rgb(168,168,168)",
              color: "black",
            },
          },
        },
        yAxis: {
          name: "單位/個",
          type: "value",
          axisLine: {
            show: true,
            lineStyle: {
              width: 2,
              // color: "rgb(168,168,168)",
              color: "black",
            },
            boundaryGap: true,
          },
          splitLine: {
            lineStyle: {
              color: "rgb(168,168,168)",
            },
          },
          axisTick: {
            show: false, // 隐藏 Y 轴刻度线
          },
        },
        grid: {
          left: "10%",
          bottom: "15%", // 设置图表绘图区域距离容器底部的距离
        },
        series: [
          {
            data: this.orderInfo.order_num,
            name: "訂單量",
            type: "bar",
            barWidth: 10,
            itemStyle: {
              color: "#91b0ff",
              // barBorderRadius: [5, 5, 0, 0],
            },
          },
        ],
      };
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    // 获取订单信息
    async getOrderInfoObject() {
      try {
        const { data } = await getOrderInfo();
        this.orderInfo = data;
        this.initLineChart();
        this.initBarChart();
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.contents {
  display: flex;
  justify-content: space-around;
}
#lineChart {
  width: 700px;
  height: 350px;
}
#barChart {
  width: 700px;
  height: 350px;
}
</style>
