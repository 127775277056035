<template>
  <div class="contents">
    <div id="PieChart"></div>
    <!-- <el-empty v-else description="暫時還沒有數據哦"></el-empty> -->
  </div>
</template>

<script>
import { getGoodsTop } from "@/api/homePage";
export default {
  data() {
    return {
      topList: [],
    };
  },
  created() {
    this.getGoodsTopInfo();
  },
  mounted() {},
  methods: {
    initPieChart() {
      let PieChart = document.getElementById("PieChart");
      const myChart = this.$echarts.init(PieChart);
      const option = {
        tooltip: {
          //   trigger: "item",
          textStyle: {
            color: "balck", // 设置文字颜色为黑色
          },
          borderWidth: 1, // 设置边框宽度为1像素
          borderColor: "#999999", // 设置边框颜色为灰色
          backgroundColor: "#fff", // 设置背景颜色为白色
        },

        legend: {
          orient: "vertical",
          left: "65%", // 设置图例位置
          top: "center", // 设置图例在垂直方向居中
          formatter: function (name) {
            var seriesData = option.series[0].data;
            var dataItem = seriesData.find(function (item) {
              return item.name === name;
            });
            return name + " : " + dataItem.value;
          },
          icon: "circle",
        },
        series: [
          {
            type: "pie",
            radius: "80%",
            center: ["40%", "50%"],
            data: this.topList,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };

      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    async getGoodsTopInfo() {
      try {
        const { data } = await getGoodsTop();
        this.topList = data.sale;
        this.initPieChart();
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#PieChart {
  width: 100%;
  height: 330px;
}
</style>
