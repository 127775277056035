import http from "../utils/request";
// 获取订单信息
export const getOrderInfo = () => {
  return http({
    url: "/admin/overview/order",
    method: "get",
  });
};

// 获取top10排行榜
export const getGoodsTop = () => {
  return http({
    url: "/admin/overview/goods",
    method: "get",
  });
};
