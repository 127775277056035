<template>
  <div class="content">
    <!-- 折线图 -->
    <div class="echartsBox">
      <ChartView></ChartView>
    </div>
    <div class="echartsBox PiechartBox">
      <div class="text">商品銷量<span>Top10</span></div>
      <Piechart></Piechart>
    </div>
  </div>
</template>

<script>
import ChartView from "./components/ChartView.vue";
import Piechart from "./components/Piechart.vue";
export default {
  name: "homepage",
  data() {
    return {};
  },
  components: { ChartView, Piechart },
  created() {},
  methods: {},
};
</script>

<style scoped lang="scss">
.content {
  padding: 10px;
  box-sizing: border-box;
  .echartsBox {
    width: 100%;
    height: 400px;
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 12%);
  }
  .PiechartBox {
    height: 430px;
    margin-top: 20px;
    .text {
      height: 38px;
      margin-bottom: 20px;
      font-size: 25px;
      font-weight: 700;
      color: black;
      span {
        color: red;
      }
    }
  }
}
</style>
